import instance from 'Src/common/utilities/axios_util';
import {
  ADMIN_SPACES_BASE_PATH,
  SPACES_BASE_PATH,
  POST_REPORT_ABUSE_PATH,
  SPACE_CATEGORIES_API_PATH,
  DIRECTORIES_API_PATH,
  SPACE_POST_SEND_EMAIL,
  EDIT_POST_PATH,
  PIN_POST_PATH,
  FOLLOW_POST_PATH,
  UNFOLLOW_POST_PATH,
  SPACE_SUBSCRIBERS_LIST_PATH,
  SEND_MESSAGE_PATH,
  LIST_MEMBERS_PATH,
} from 'Src/spaces/endpoints';
import FIELDS from './fields';

export const fetchSpaces = (
  { fields = 'id,name,description,space_url,slug,banner_image_asset', extraQueryParams = '' } = {
    fields: 'id,name,description,space_url,slug,banner_image_asset',
    extraQueryParams: '',
  },
) =>
  instance.get(ADMIN_SPACES_BASE_PATH.concat(`?fields=${fields}&skip_pagination=1&${extraQueryParams}`), {
    timeout: 120000,
  });

// This is for feed
export const fetchAllSpaces = () =>
  instance.get(
    ADMIN_SPACES_BASE_PATH.concat(
      '?fields=id,name,description,banner_image_asset,latest_post_at,is_verified,count_subscribers,groups&skip_pagination=1',
    ),
  );

export const fetchSpace = (spaceId, fields = FIELDS.SPACE) =>
  instance.get(ADMIN_SPACES_BASE_PATH.concat(`${spaceId}/?fields=${fields}`), { hideNotification: true });

export const createPost = (postData) => instance.post(SPACES_BASE_PATH.concat('posts/'), postData);

export const deletePost = (postId) => instance.delete(SPACES_BASE_PATH.concat(`posts/${postId}/`));

export const createChannel = (spaceId, body) => instance.post(SPACES_BASE_PATH.concat(`${spaceId}/channels`), body);

export const fetchChannels = (spaceId) =>
  instance.get(SPACES_BASE_PATH.concat(`${spaceId}/channels?fields=id,channel_url,name,permissions`));

export const fetchSpaceFeed = (spaceId, channelId, page) => {
  let url = `${spaceId}/space_feed/?page=${page}&fields=${FIELDS.POST}`;
  if (channelId) {
    url = url.concat(`&channel_id=${channelId}`);
  }
  return instance.get(SPACES_BASE_PATH.concat(url), { hideNotification: true });
};

export const fetchUserFeed = (page) =>
  instance.get(SPACES_BASE_PATH.concat(`user_feed/?page=${page}&fields=${FIELDS.POST}`));

export const fetchSpacePost = (postId) =>
  instance.get(SPACES_BASE_PATH.concat(`posts/${postId}/?fields=${FIELDS.POST}`), { hideNotification: true });

export const fetchComments = (postId, page = 1) =>
  instance.get(SPACES_BASE_PATH.concat(`posts/${postId}/comments/?page=${page}&fields=${FIELDS.COMMENT}`));

export const addSpacePost = (postId, data) => instance.post(SPACES_BASE_PATH.concat(`posts/${postId}/comments/`), data);

export const reorderSpaceChannels = (spaceId, channels) =>
  instance.post(SPACES_BASE_PATH.concat(`${spaceId}/channels/reorder/`), { channels });

export const isUserVerified = (endpoint) => instance.get(endpoint, { hideNotification: true });

export const getUserProfile = () => instance.get('/profiles/my_profile/', { hideNotification: true });

export const getSpaceSettings = () => instance.get(SPACES_BASE_PATH.concat('settings/'));

export const updateSpaceDigestSettings = (isSubscribed) =>
  instance.post(SPACES_BASE_PATH.concat('settings/'), { is_subscribed_to_digest: isSubscribed });

export const updateChannelSubscription = (spaceId, channelId, canUnsubscribe) => {
  if (canUnsubscribe) {
    return instance.post(SPACES_BASE_PATH.concat(`${spaceId}/channels/${channelId}/unsubscribe`));
  }
  return instance.post(SPACES_BASE_PATH.concat(`${spaceId}/channels/${channelId}/subscribe`));
};

export const updateChannel = (spaceId, channelId, data) =>
  instance.patch(SPACES_BASE_PATH.concat(`${spaceId}/channels/${channelId}/`), data);

export const deleteChannel = (spaceId, channelId) =>
  instance.delete(SPACES_BASE_PATH.concat(`${spaceId}/channels/${channelId}/`));

export const fetchRelatedPosts = (postId) =>
  instance.get(SPACES_BASE_PATH.concat(`posts/${postId}/related_posts/?fields=${FIELDS.POST}`));

export const deleteComment = (postId, commentId) =>
  instance.delete(SPACES_BASE_PATH.concat(`posts/${postId}/comments/${commentId}`));

export const updateOrDeleteReaction = (method, data, postId) => {
  if (method === 'post') {
    return instance.post(SPACES_BASE_PATH.concat(`posts/${postId}/reactions/`), data, {
      hideNotification: true,
    });
  }
  return instance.delete(SPACES_BASE_PATH.concat(`posts/${postId}/reactions/`), {
    hideNotification: true,
    data,
  });
};

export const fetchNextPost = (postId) =>
  instance.get(SPACES_BASE_PATH.concat(`posts/${postId}/next_post/?fields=${FIELDS.POST}&origin=user_feed`), {
    hideNotification: true,
  });

export const createResource = (spaceId, body) => instance.post(SPACES_BASE_PATH.concat(`${spaceId}/resources`), body);

export const deleteResource = (spaceId, resourceId) =>
  instance.delete(SPACES_BASE_PATH.concat(`${spaceId}/resources/${resourceId}`));

export const updateResource = (spaceId, resourceId, data) =>
  instance.patch(SPACES_BASE_PATH.concat(`${spaceId}/resources/${resourceId}/`), data);

export const subscribeSpace = (spaceId) => instance.post(SPACES_BASE_PATH.concat(`${spaceId}/subscribe/`), {});

export const unsubscribeSpace = (spaceId) => instance.post(SPACES_BASE_PATH.concat(`${spaceId}/unsubscribe/`), {});

export const reportAbusePost = (postId) =>
  instance.post(POST_REPORT_ABUSE_PATH.replace(':postId', postId), { post_id: postId });

export const fetchAllCategories = () =>
  instance.get(SPACE_CATEGORIES_API_PATH.concat('?skip_pagination=1&space_count__gte=1'));

export const fetchAllDirectories = () =>
  instance.get(DIRECTORIES_API_PATH.concat('?skip_pagination&fields=name,id,slug'), {
    baseURL: '/api/v2/manage/',
  });

export const fetchDirectory = (id) =>
  instance.get(DIRECTORIES_API_PATH.concat(`/${id}`), {
    baseURL: '/api/v2/manage/',
  });

export const sendPostAsEmail = (spaceId, postId) =>
  instance.get(SPACE_POST_SEND_EMAIL.replace(':spaceId', spaceId).replace(':postId', postId));

export const editPost = (id, data) => instance.patch(EDIT_POST_PATH.replace(':postId', id), data);

export const getPinnedPosts = (spaceId, channelId) => {
  let url = `${SPACES_BASE_PATH}${spaceId}/space_feed/?skip_pagination=true&fields=id,heading,is_pinned,post_url&is_pinned=true&ordering=-pinned_at`;
  if (channelId) {
    url = url.concat(`&channel_id=${channelId}`);
  }
  return instance.get(url);
};

export const pinPost = (postId, data) => instance.patch(PIN_POST_PATH.replace(':postId', postId), data);

export const reorderSpaceResources = (spaceId, resources) =>
  instance.post(SPACES_BASE_PATH.concat(`${spaceId}/resources/reorder/`), { resources });

export const followPost = (postId, config) =>
  instance.post(FOLLOW_POST_PATH.replace(':postId', postId), {}, { ...config });

export const unfollowPost = (subscriberId, config) =>
  instance.delete(UNFOLLOW_POST_PATH.replace(':subscriberId', subscriberId), { ...config });

export const fetchSubscribersList = (spaceId, params = '') =>
  instance.get(`${SPACE_SUBSCRIBERS_LIST_PATH.replace(':spaceId', spaceId)}?${params}`, { hideNotification: true });

export const sendMessage = (data) =>
  instance.post(SEND_MESSAGE_PATH, data, {
    baseURL: '',
  });

export const getMembers = (spaceId, search = '') =>
  instance.get(
    `${LIST_MEMBERS_PATH.replace('{spaceId}', spaceId)}?page=1&page_size=50${search ? `&search=${search}` : ''}`,
  );
