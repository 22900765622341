/* eslint-disable max-nested-callbacks */
/* eslint-disable max-statements */
import React, { useContext, useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { shape, arrayOf } from 'prop-types';
import { cloneDeep, debounce, forEach, isEmpty, merge } from 'lodash';
import { Modal, message, notification, Button } from 'antd';
import Tribute from 'tributejs';
import ReactDOMServer from 'react-dom/server';
import { ImagePreview } from 'arcl';
import PostComponent from 'Src/spaces/components/post';
import {
  addSpacePost,
  fetchSpacePost,
  fetchComments,
  deleteComment,
  deletePost,
  updateOrDeleteReaction,
  fetchNextPost,
  reportAbusePost,
  sendPostAsEmail,
  editPost,
  pinPost,
  followPost,
  unfollowPost,
  getMembers,
} from 'Src/spaces/actions';
import transformComment from 'Src/spaces/adapters/comment';
import VerificationContext from 'Src/common/context/verification';
import UserProfileContext from 'Src/spaces/context/user';
import HybridAuthContext from 'Src/spaces/context/auth';
import { handleRedirect, onReaction } from 'Src/spaces/utils';
import * as ROUTES from 'Src/spaces/routes';
import { POST_ACTION } from 'Src/spaces/constants';
import UpdatePostModal from 'Src/spaces/components/createPost/modal';
import AlmasightsProvider from 'Src/common/providers/almasights';
import WaitingScreen from 'Src/common/components/waitingScreen';
import {
  formatPost,
  fetchPostAttachments,
  encodeTaggedUsersText,
  decodeTaggedUsersText,
} from 'Src/common/utilities/spaces_utils';
import { getTemplate } from 'Src/spaces/components/common';
import './style.scss';

const { confirm } = Modal;
let postId = null;
let page = 1;
let key = `open${Date.now()}`;

/**
 * Post Container
 */
function Post({ history, match, verificationGroups }) {
  const { userProfile } = useContext(UserProfileContext);
  const { isUserAuthenticated } = useContext(HybridAuthContext);
  const verificationData = useContext(VerificationContext);
  const [post, setPost] = useState({});
  const [comments, setComments] = useState({});
  const [commentCount, setCommentCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [replyRef, setReplyRef] = useState(null);
  const [is404, setIs404] = useState(false);
  const [reverse, setReverse] = useState(false);
  const [postAttachments, setPostAttachments] = useState({});
  const [isApplyForVerificationModalVisible, setIsApplyForVerificationModalVisible] = useState(false);
  const { isVerified, isVerifying, space_id, space_name } = useContext(VerificationContext);
  const [activeImageIndex, setActiveImageIndex] = useState();
  const [previewImageList, setPreviewImageList] = useState([]);
  const [postToUpdate, setPostToUpdate] = useState('');
  const [hasMoreComments, setHasMoreComments] = useState(false);
  const [taggedUsers, setTaggedUsers] = useState([]);

  const postRef = useRef();

  postRef.current = post;

  function onReactionL(postId, summary) {
    updateOrDeleteReaction(summary.self ? 'delete' : 'post', { kind: summary.kind }, postId);
    setPost({ ...onReaction([postRef.current], postId, summary)[0] });
  }

  function transformAllCommentsText(data) {
    forEach(data, (item) => {
      if (typeof item.description !== 'object') {
        item.description = <span dangerouslySetInnerHTML={{ __html: decodeTaggedUsersText(item.description).data }} />;
      }
    });

    return data;
  }

  /**
   * Handles image click
   */
  function handleImageClick(index, images) {
    const arr = [];
    images.forEach((image) => {
      arr.push({ url: image.url, shareUrl: postRef.current.post_url, shareText: postRef.current.heading });
    });
    setActiveImageIndex(index);
    setPreviewImageList(arr);
  }

  function setPostDetails(postData) {
    postId = postData.id;
    page = 1;
    formatPost(
      [postData],
      (newPosts) => {
        const fetchedPost = newPosts[0];
        // setting follow config
        if (fetchedPost?.permissions?.can_create_comment) {
          if (fetchedPost.followed) {
            Object.assign(fetchedPost, {
              followedConfig: {
                active: true,
                id: fetchedPost.followedConfig,
              },
            });
          } else {
            Object.assign(fetchedPost, {
              followedConfig: {
                active: false,
                id: '',
              },
            });
          }
        }
        setPost(fetchedPost);
        newPosts.forEach((newPost) => {
          fetchPostAttachments(newPost, (attachments) => {
            postAttachments[newPost.id] = attachments;
            setPostAttachments({ ...postAttachments });
          });
        });
        fetchComments(postId, page).then((commentRes) => {
          const data = commentRes.data.results;
          data.forEach((d, index) => {
            commentRes.data.results[index] = transformComment(d);
          });
          setCommentCount(commentRes.data.count);
          setComments(transformAllCommentsText(commentRes.data.results));
          setHasMoreComments(commentRes.data.next);
          setLoading(false);
        });
      },
      (input, routeName) => handleRedirect(input, routeName, verificationData),
      (postId, summary) => onReactionL(postId, summary),
      false,
      handleImageClick,
    );
  }

  useEffect(() => {
    if (!isVerifying) {
      setLoading(true);
      fetchSpacePost(match.params.postId)
        .then((res) => setPostDetails(res.data))
        .catch((error) => {
          if (error.response.status === 404) {
            setIs404(true);
          }
          setLoading(false);
        });
    }
  }, [isVerifying, isVerified, isUserAuthenticated, window.location.pathname]);

  useEffect(() => {
    post.attachments = postAttachments[post.id] ? postAttachments[post.id] : [];
    setPost({ ...post });
  }, [postAttachments]);

  /**
   * Handle escape key press
   */
  function handleEscKeyDown(e) {
    // Checking target id too for ignoring escape press event when image preview modal is open
    if (e.key === 'Escape' && e.target.id === 'post-details-container') {
      setReverse(true);
      setTimeout(() => {
        history.goBack();
      }, 300);
    }
  }

  useEffect(() => {
    const container = document.getElementById('post-details-container');
    // Setting focus on first render for escape to go back functionality
    container.focus();
    container.addEventListener('keyup', handleEscKeyDown);
    return () => container.removeEventListener('keyup', handleEscKeyDown);
  }, []);

  useEffect(() => {
    if (replyRef?.current) {
      const replyElement = document.querySelector('.comments-container textarea');
      const tribute = new Tribute({
        values: debounce((text, cb) => {
          getMembers(post.space.id, text).then((res) => {
            forEach(res.data.results, (item) => {
              merge(item, item.profile, { key: item.profile.full_name });
              delete item.profile;
            });
            cb(res.data.results);

            const disabledItems = document.querySelectorAll('.disabled-menu-item.tribute-menu-item');

            forEach(disabledItems, (item) => {
              item?.parentNode?.classList?.add('disabled-menu-item');
            });
          });
        }, 300),
        lookup: 'key',
        fillAttr: 'key',
        selectTemplate: (item) => {
          setTaggedUsers((prevTaggedUsers) => [...prevTaggedUsers, item]);
          replyRef.current.setValue(
            replyRef.current.state.value.replace(/@([a-zA-Z0-9_]*)?$/, () => `@${item.original.key} `),
          );
          return `@${item.original.key} `;
        },
        menuItemTemplate: (item) => ReactDOMServer.renderToString(getTemplate(item)),
        noMatchTemplate: '',
        containerClass: 'tribute-container comment-tribute-container',
        menuContainer: document.getElementById('reply-component'),
      });

      tribute?.attach(replyElement);
    }
  }, [replyRef]);

  /**
   * Handles add comment
   */
  function addComment(text) {
    addSpacePost(postId, { text: encodeTaggedUsersText(text, taggedUsers) })
      .then((res) => {
        setTaggedUsers([]);
        if (replyRef && replyRef.current) {
          replyRef.current.setValue('');
        }
        const formattedComment = transformComment(res.data);
        const clonedPosts = cloneDeep(post);
        if (res.data.subscriber_id && clonedPosts.followed !== res.data.subscriber_id) {
          Object.assign(clonedPosts, {
            followed: res.data.subscriber_id,
            followedConfig: {
              active: true,
              id: res.data.subscriber_id,
            },
          });
          setPost({ ...clonedPosts });
          followSuccessMessage(res.data.subscriber_id);
        }
        setComments(transformAllCommentsText([...comments, formattedComment]));
        setCommentCount(commentCount + 1);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setIs404(true);
        }
        setLoading(false);
      });
  }

  /**
   * Sets ref of textarea
   */
  function setRef(ref) {
    setReplyRef(ref);
  }
  /**
   * Handles comment menu
   */
  function handleCommentMenu(commentId, key) {
    if (key === 'delete') {
      confirm({
        title: 'Delete Comment',
        content: 'Deleted comments cannot be retrieved. Would you like to proceed?',
        okText: 'Delete Comment',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk() {
          deleteComment(postId, commentId);
          // if post was newly created and user haven't refreshed the page
          const filteredPost = comments.filter((comment) => comment.id !== commentId);
          setComments(transformAllCommentsText(filteredPost));
          setCommentCount(commentCount - 1);
        },
      });
    }
  }

  function showEmailSendConfirmationModal(postId) {
    confirm({
      title: 'Are you sure to send this post as announcement',
      content: 'As a moderator, you can send this post out immediately to all subscribers',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        const spaceId = postRef.current?.space?.id;
        sendPostAsEmail(spaceId, postId).then(() => {
          message.success('Post has been sent as email');
        });
      },
    });
  }

  /**
   * Handles click on menu item
   */
  function handleMenuClick(id, key) {
    if (key === POST_ACTION.DELETE) {
      showDeletePostModal(id);
    } else if (key === POST_ACTION.REPORT_ABUSE) {
      reportAbusePost(postId).then((res) => {
        message.success('This post has been reported to the moderators of this space');
        // Remove report_abuse menu item from post
        const clonedPosts = cloneDeep(post);
        const postItems = clonedPosts.menuItems.filter((item) => item.key !== POST_ACTION.REPORT_ABUSE);
        clonedPosts.permissions.can_flag_post = false;
        clonedPosts.menuItems = postItems;
        setPost({ ...clonedPosts });
      });
    } else if (key === POST_ACTION.SEND_AS_EMAIL) {
      showEmailSendConfirmationModal(id);
    } else if (key === POST_ACTION.EDIT_POST) {
      setPostToUpdate(id);
    } else if (key === POST_ACTION.PIN_POST) {
      handlePinPost(id);
    }
  }

  const followSuccessMessage = (subscriberId) => {
    key = `open${Date.now()}`;
    const btn = (
      <Button
        className="arc-btn-subtle"
        onClick={() => {
          handleFollowChange(subscriberId);
        }}>
        Unfollow
      </Button>
    );
    notification.open({
      className: 'custom-follow-message',
      message: 'You are following this post',
      description: 'You will now receive notifications when someone comments on this post.',
      btn,
      key,
      duration: 10,
    });
  };

  /**
   * Toggle follow/unfllow
   */
  const handleFollowChange = (subscriberId = null, config = {}) => {
    notification.close(key);
    if (post.followed || subscriberId) {
      if (subscriberId === null) {
        subscriberId = post.followed;
      }
      unfollowPost(subscriberId, config).then((res) => {
        // changing followed variable
        const clonedPosts = cloneDeep(post);
        clonedPosts.followed = false;
        message.success('You are not following this post anymore');
        setPost({
          ...clonedPosts,
          followedConfig: {
            active: false,
            id: '',
          },
        });
      });
    } else {
      followPost(post.id, config).then((res) => {
        // changing followed variable
        const clonedPosts = cloneDeep(post);
        clonedPosts.followed = res.data.id;
        setPost({
          ...clonedPosts,
          followedConfig: {
            active: true,
            id: res.data.id,
          },
        });
        followSuccessMessage(res.data.id);
      });
    }
  };

  /**
   * Toggles apply for verification modal
   */
  function toggleApplyForVerificationModal() {
    setIsApplyForVerificationModalVisible(!isApplyForVerificationModalVisible);
  }

  /**
   * Scrolls to reply component
   */
  function handleScrollToReply() {
    document.getElementById('reply-component').scrollIntoView({ behavior: 'smooth' });
    if (replyRef?.current) {
      replyRef.current.focus();
    }
  }

  function getNextPost() {
    fetchNextPost(postRef.current.id)
      .then((res) => {
        setPostDetails(res.data);
        history.push(ROUTES.POST_PATH.replace(':postId', res.data.id));
      })
      .catch(() => {
        setLoading(false);
        history.push(ROUTES.FEEDS_PATH);
      });
  }

  function showDeletePostModal(id) {
    confirm({
      title: 'Delete Post',
      content: 'Deleted posts cannot be retrieved. Would you like to proceed?',
      okText: 'Delete Post',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        deletePost(id);
        history.push(ROUTES.FEEDS_PATH);
      },
    });
  }

  /**
   * Handles preview modal close
   */
  function handleImagePreviewClose() {
    setPreviewImageList([]);
    // Workaround:- Setting focus back to post details container after closing image preview modal to make escape button event work
    setTimeout(() => {
      const container = document.getElementById('post-details-container');
      container.focus();
    }, 100);
  }

  /**
   * Closes update post modal
   */
  function handleUpdatePostModalClose() {
    setPostToUpdate('');
  }

  function handlePostUpdateSubmit(value, sendAsEmail) {
    value.is_edited = true;
    editPost(postToUpdate, value).then((res) => {
      setPostDetails(res.data);
      if (sendAsEmail) {
        sendPostAsEmail(res.data.space?.id, res.data.id).then(() => {
          message.success('Post has been sent as email');
        });
      }
    });
    setPostToUpdate('');
  }

  function handlePinPost(postId) {
    pinPost(postId, { is_pinned: !post.isPinned }).then((res) => {
      setPostDetails(res.data);
    });
  }

  function fetchMoreComments() {
    page += 1;
    fetchComments(postId, page).then((commentRes) => {
      let clonedComments = cloneDeep(comments);
      const data = commentRes.data.results;
      data.forEach((newComment, index) => {
        commentRes.data.results[index] = transformComment(newComment);
        clonedComments = clonedComments.filter((comment) => newComment.id !== comment.id);
      });
      setCommentCount(commentRes.data.count);
      setComments(transformAllCommentsText([...clonedComments, ...commentRes.data.results]));
      setHasMoreComments(commentRes.data.next);
    });
  }

  return (
    <div id="post-details-container" tabIndex={0}>
      <Choose>
        <When condition={isVerifying || loading}>
          <WaitingScreen text={loading ? 'Getting post' : 'Checking Verifications'} />
        </When>
        <Otherwise>
          <AlmasightsProvider
            parentObject={{
              type: 'space',
              id: space_id,
              name: space_name,
            }}>
            <div>
              <PostComponent
                post={post}
                is404={is404}
                setRef={setRef}
                comments={comments}
                addComment={addComment}
                userProfile={userProfile}
                commentCount={commentCount}
                handleMenuClick={handleMenuClick}
                verificationData={verificationData}
                handleCommentMenu={handleCommentMenu}
                verificationGroups={verificationGroups}
                isUserAuthenticated={isUserAuthenticated}
                handleScrollToReply={handleScrollToReply}
                toggleApplyForVerificationModal={toggleApplyForVerificationModal}
                isApplyForVerificationModalVisible={isApplyForVerificationModalVisible}
                getNextPost={getNextPost}
                hasMoreComments={hasMoreComments}
                fetchMoreComments={fetchMoreComments}
                handleFollowChange={handleFollowChange}
              />
              <If condition={!isEmpty(previewImageList)}>
                <ImagePreview
                  images={previewImageList}
                  activeIndex={activeImageIndex}
                  onClose={handleImagePreviewClose}
                />
              </If>
              <If condition={!isEmpty(postToUpdate)}>
                <UpdatePostModal
                  postData={post}
                  spaceId={post.space.id}
                  togglePostModal={handleUpdatePostModalClose}
                  handleSubmit={handlePostUpdateSubmit}
                />
              </If>
            </div>
          </AlmasightsProvider>
        </Otherwise>
      </Choose>
    </div>
  );
}

Post.propTypes = {
  history: shape().isRequired,
  match: shape().isRequired,
  verificationGroups: arrayOf(shape()).isRequired,
};

export default withRouter(Post);
